import styled from 'styled-components'
import { Colors } from '../../core/colors'
import { motion } from 'framer-motion'
import { Fonts } from '../../core/fonts'


export const CardWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  margin-bottom: ${props => props.overlay ? '0' : '20px'};
  transform: translateY(${props => props.overlay ? '-33px' : '0'});
  background-image:  ${props => props.src || 'none'};
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.centered ? 'center' : ''};
  width: 100%;
  background: ${Colors.white};
  box-shadow: 0 15px 45px -9px rgba(0,0,0,.2);
  border-radius: 2px;   
  margin: 0;
  text-align: center;
`

export const CardHeadline = styled(motion.h2)`
    position: relative;
    font-weight: 900;
    font-size: 1.75rem;
    color: ${Colors.text};
    font-family: ${Fonts.default};
    font-stretch: condensed;
    text-transform: uppercase;
    margin-bottom: 32px;
`

export const CardLabel = styled(motion.h4)`
    position: relative;
    font-weight: 200;
    font-size: 1.2rem;
    color: ${Colors.primary};
    font-family: ${Fonts.default};
    font-stretch: condensed;
    margin-bottom: 8px;
    margin-top: 32px;
`

export const CardText = styled.p`
    display: inline-flex;
    margin-bottom: 18px;
    line-height: 1.5rem;
    color: ${Colors.text}
`

