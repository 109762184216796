import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Colors } from '../../core/colors'
import { Fonts } from '../../core/fonts'

export const BaseButton = styled(motion.a)`
    position: relative;
    background: ${props => props.background || Colors.primary};
    color: ${props => props.color || Colors.white};
    box-shadow: 0 15px 45px -9px rgba(0,0,0,.2);
    padding: 18px 24px;
    border-radius: 3px;
    border: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-family: ${Fonts.headline};
`

export const PrimaryButton = styled(BaseButton)`
    background: ${Colors.primary};
    color: ${Colors.white};
`

export const SecondaryButton = styled(BaseButton)`
    background: ${Colors.white};
    color: ${Colors.primary};
`
