import React from 'react'
import { PrimaryButton, SecondaryButton } from './styles'
import { ButtonHoverAnimation } from './animations'

export const ButtonTypes = {
  Primary: 'primary',
  Secondary: 'secondary'
}

export default class Button extends React.Component {


  render() {
    const { children, type } = this.props

    switch (type) {
      case 'primary':
        return <PrimaryButton {...this.props} {...ButtonHoverAnimation}>{children}</PrimaryButton>;
      case 'secondary':
        return <SecondaryButton {...this.props} {...ButtonHoverAnimation}>{children}</SecondaryButton>;
      default:
        return <PrimaryButton {...this.props} {...ButtonHoverAnimation}>{children}</PrimaryButton>;
    }
  }
}
