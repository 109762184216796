import * as React from 'react'
import { CenteredCard } from '../../components/shared/card'
import Layout from '../../layout'
import { MediumHeader } from '../../components/shared/header'
import { graphql } from 'gatsby'
import { Image, ReferencesContainer, ReferencesWrapper, Label, Reference } from './styles'

import * as references from '../../content/references.yml'

export default class ReferencesPage extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {

    const referenceImages = this.props.data.references.nodes

    return (
      <Layout location={'/references'}>
        <MediumHeader title={'Projekte & Referenzen'}
                      srcSet={this.props.data.headerBackground.childImageSharp.fluid.srcSet}/>
        <CenteredCard centered label={''}
                      headline={'Referenzen'}/>

        <ReferencesWrapper>
          <ReferencesContainer>
            {references.sort((a, b) => b.year - a.year)
              .map(ref =>
                <Reference>
                  <Image
                    key={ref.title}
                    alt={ref.title}
                    srcSet={referenceImages.map(refImg => refImg.childImageSharp.fixed)
                      .find(refImg => refImg.originalName === ref.image).srcSet}/>
                  <Label>{ref.title}</Label>
                </Reference>,
              )}
          </ReferencesContainer>
        </ReferencesWrapper>
      </Layout>
    )
  }
}

export const ReferencesQuery = graphql`{
    headerBackground: file(name: {eq: "references-header"}) {
        childImageSharp {
            fluid {
                srcSet
            }
        }
    }
    references: allFile(filter: {relativeDirectory: {eq: "images/references"}}) {
        nodes {
            childImageSharp {
                fixed(width: 500 height:300 cropFocus:CENTER) {
                    srcSet
                    originalName
                }
            }
        }
    }
}
`
