import React from 'react'
import { Card, CardHeadline, CardLabel, CardText, CardWrapper } from './styles'


export default class CenteredCard extends React.Component {

  render() {
    const { background, overlay, centered, label, headline, text } = this.props
    return (
      <>
        <CardWrapper src={background} overlay={overlay}>
          <Card centered={centered}>
            <CardLabel>{label}</CardLabel>
            <CardHeadline>{headline}</CardHeadline>
            <CardText>{text}</CardText>
          </Card>
        </CardWrapper>
      </>
    )

  }
}

