import styled from 'styled-components'
import { Device } from '../../components/core/responsive'
import { motion } from 'framer-motion'
import { Colors } from '../../components/core/colors'

export const ReferencesWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
`
export const ReferencesContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;

  @media (${Device.tablet}){
    margin: 0;
    flex-direction: row;
  }
`

export const Image = styled(motion.img)`
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  overflow: hidden;
`

export const Label = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 100%;
  height: 50px;
  background: rgba(44,160,103, 0.9);
 
`

export const Reference = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 500px;
  position: relative;
  color: ${Colors.white};
  font-size: 1.25rem;

  @media (${Device.tablet}){
    max-width: 50%;
  }
  
  @media (${Device.laptop}){
    max-width: 33.33%;
  }
`
