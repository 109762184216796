import React from 'react'

import { HeaderIcon } from '../icons'
import { Colors } from '../../core/colors'
import { HeaderWrapper, Headline, HeroImage, MediumHeaderContainer } from './styles'
import { HeaderIconAnimation, HeroImageAnimation, MediumHeadlineAnimation } from './animations'

export default class MediumHeader extends React.Component {

  headerSize;

  componentDidMount() {
    this.headerSize = window.innerWidth;
  }

  render() {
    const { title, srcSet } = this.props
    return (
      <HeaderWrapper as="header">
        <HeroImage srcSet={srcSet} {...HeroImageAnimation}  alt={'Amann Massiv- & Betonbau'}/>

        <HeaderIcon className={'header-icon'}
                    size={this.headerSize}
                    height={'80%'}
                    diamondColor={Colors.black}
                    color={Colors.primary}
                    viewBox={'0 0 100 50'}
                    {...HeaderIconAnimation} />

        <MediumHeaderContainer>
          <Headline {...MediumHeadlineAnimation}>{title}</Headline>
        </MediumHeaderContainer>
      </HeaderWrapper>
    )
  }

}
