import React from 'react';

export const HeroImageAnimation = {
  initial: {
    scale: 1.1
  },
  animate: {
    scale: 1
  },
  transition: {
    type: 'spring',
    stiffness: 260,
    damping: 100
  }
};

export const HeaderIconAnimation = {
  initial: {
    opacity: .5,
    scale: 2
  },
  animate: {
    opacity: 1,
    scale: 1
  },
  transition: {
    type: 'spring',
    stiffness: 300,
    damping: 40
  }
};

export const HeadlineAnimation = {
  initial: {
    left: '-200px',
    opacity: 0
  },
  animate: {
    left: '0',
    opacity: 1
  },
  transition: {
    delay: .5,
    type: 'spring',
    damping: 20
  }
};

export const MediumHeadlineAnimation = {
  initial: {
    top: '100px',
    opacity: 0
  },
  animate: {
    top: '0',
    opacity: 1
  },
  transition: {
    delay: .5,
    type: 'spring',
    damping: 20
  }
};


export const HeadlineButtonAnimation = {
  initial: {
    left: '-200px',
    opacity: 0
  },
  animate: {
    left: '0',
    opacity: 1
  },
  transition: {
    delay: .8,
    type: 'spring',
    damping: 20
  }
};

export const MouseIconAnimation = {
  initial: {
    transform: 'translateY(-10px)'
  },
  animate: {
    transform: 'translateY(0px)'
  },
  whileHover: {
    transform: 'translateY(-10px)'
  },
  transition: {
    type: 'spring',
    damping: 5
  }
};
