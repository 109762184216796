import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Colors } from '../../core/colors'
import { Fonts } from '../../core/fonts'
import { Container, Wrapper } from '../../core/layout'

export const HeaderWrapper = styled(Wrapper)`
  justify-self: center;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  
  & > .header-icon {
    z-index: -1;
    position: absolute;
    bottom: -15px;
    right: -20%;
    opacity: .8;
  }
   
  & > .scroll {
    position: absolute;
    bottom: 55px;
    cursor: pointer;
  }
`

export const HeaderContainer = styled(Container)`
  height: 90vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

export const MediumHeaderContainer = styled(Container)`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const HeroImage = styled(motion.img)`
    position: absolute;
    object-fit: cover;
    z-index: -2;
    top: 0;
    height: 100%;
    width: 100%;
`

export const Headline = styled(motion.h1)`
    position: relative;
    font-weight: 900;
    font-size: 3rem;
    color: ${Colors.white};
    font-family: ${Fonts.default};
    font-stretch: condensed;
    margin-bottom: 24px;
`
