import React from 'react'

import { HeaderIcon, MouseIcon } from '../icons'
import { Colors } from '../../core/colors'
import Button from '../button'
import { HeaderContainer, HeaderWrapper, Headline, HeroImage } from './styles'
import {
  HeaderIconAnimation,
  HeadlineAnimation,
  HeadlineButtonAnimation, HeroImageAnimation,
  MouseIconAnimation,
} from './animations'

export default class BigHeader extends React.Component {

  headerSize;

  componentDidMount() {
    this.headerSize = window.innerWidth;
  }

  handleMouseIconClick() {
    window.scrollTo({
      top: 720,
      behavior: 'smooth'
    });
  }

  render() {
    const { title, buttonLabel, href, srcSet } = this.props
    return (
      <HeaderWrapper as="header">
        <HeroImage {...HeroImageAnimation} srcSet={srcSet} alt={'Amann Massiv- & Betonbau'}/>
        <HeaderIcon className={'header-icon'}
                    size={this.headerSize}
                    height={'75%'}
                    diamondColor={Colors.black}
                    color={Colors.primary}
                    viewBox={'0 0 100 50'}
                    {...HeaderIconAnimation} />

        <HeaderContainer>
          <Headline {...HeadlineAnimation}>{title}</Headline>
          <Button href={href} {...HeadlineButtonAnimation}>{buttonLabel}</Button>
        </HeaderContainer>

        <MouseIcon onClick={this.handleMouseIconClick} {...MouseIconAnimation} size={'3rem'}
                   color={Colors.white}
                   className={'scroll'}/>
      </HeaderWrapper>
    )
  }

}
